import React from 'react';
import { Link } from "react-router-dom";
import "./CreatorPromise.css";
import Nav from '../components/Nav';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import arrowleft from "../assets/icons/arrow-left.svg";

function CreatorPromise() {
    return (
        <div>
            <Nav />
            <div className='creatorpromise'>
            <header className="creatorpromise__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover07.webp?alt=media&token=95547334-c7b3-4892-bbde-6a492bb948cf"
                )`, 
                backgroundPosition: "top center",
            }}>
            <div className='creatorpromise__content'>
                <Link to="/" className="creatorpromise__back" >
                    <img 
                    className='creatorpromise__back__img'
                    src={arrowleft}
                    alt="to teleboing helpdesk"
                    />
                    <p className='creatorpromise__back__msg' href="/" >Regresar a la página de inicio de Ayuda</p>
                </Link>
                <h1>Ser un Creador de contenido original y entretenido en TELEBOING es una gran idea.</h1>
                {/* <img 
                    className='creatorpromise__back__img'
                    src={arrowdown}
                    alt=""
                /> */}
                <Link to="/upload">
                <button className='creatorpromise__banner__button' >
                    SUBE TU CORTO
                </button>
                </Link>
            </div>
            </header>

            <div className="creatorpromise__body" >
            <div className="creatorpromise__body__definition" >
                <h2>"Las personas quieren ver historias. Las plataformas actuales de Streaming están muy centradas en exagerar el contenido en lugar de narrar grandes historias."</h2>
                <h3>TELEBOING es un servicio de streaming de contenido multiverso que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, programas originales, sesiones de comedia Stand-up, obras de teatro y series basadas en historias fascinantes, reales y entretenidas cuyo desenlace puedes controlar, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet. Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible. Siempre hay una historia nueva por descubrir, ¡y todos los días, a cada hora, agregamos más contenido original!.</h3>
            </div>

            <div className="creatorpromise__body__offer" >
            <div>
                <p>¿Qué te ofrecemos como creador de contenido?</p>
                <ul>
                    <li>Regalías / Pago mensual estable y recurrente según contenido original publicado exclusivamente en TELEBOING.</li>
                    <li>Islas propias de investigación, producción, post-producción, redacción, guionización, recreación de noticias, narrativa y dramatización, digital y animación 3D, PR & legal y cobertura de prensa en campo.</li>
                    <li>Algoritmos de inteligencia artificial para analizar, descifrar y predecir tendencias y reacciones que produzca el contenido en los comentarios del espectador.</li>
                    <li>Ingresos extras sobre el merchandising y contenido audiovisual producido en base a tu contenido, cortometrajes, historias, investigaciones e imagen.</li>
                </ul>
                <Link to="/upload">
                <button className='creatorpromise__banner__button' >
                    SUBE TU CORTO
                </button>
                </Link>
            </div>
            <div  className='creatorpromise__body__offer__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_banner01.webp?alt=media&token=8dc28248-38ec-4513-91e6-b1fd834d187e' alt="Algoritmos de inteligencia artificial para analizar, descifrar y predecir tendencias y reacciones que produzca el contenido en los comentarios del espectador." />
            </div>
            </div>

            <div className="creatorpromise__body__followers" >
            <div  className='creatorpromise__body__followers__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_banner03.webp?alt=media&token=ae4f801a-f17c-4116-92fc-331c889652c8' alt="Crearan alertas que les avisaran cuando se publique un contenido que encaje con su perfil e intereses." />
            </div>
            <div>
            <p>¿Qué ganan tus seguidores?</p>
            <ul>
                <li>Les será fácil y rápido encontrar y saber quién ha publicado contenido nuevo y quién está transmitiendo en vivo.</li>
                <li>Podrán ver 2 o más transmisiones o contenidos a la vez, y decidir cuál les gusta más.</li>
                <li>Sabrán inmediatamente cuál es el tema de cada video y podrán compararlos usando búsquedas personalizadas según sus propios intereses.</li>
                <li>Crearán alertas que les avisarán cuando se publique un contenido que encaje con su perfil e intereses.</li>
                <li>Revivirán y se sentirán parte de las historias a través de recreaciones y animaciones de los momentos más importantes.</li>
            </ul>
            <Link to="/upload">
                <button className='creatorpromise__banner__button' >
                    SUBE TU CORTO
                </button>
            </Link>
            </div>
            </div>

            <div className="creatorpromise__body__together" >
            <div>
            <p>¿Qué lograremos juntos?</p>
            <ul>
                <li>Convertir la producción de contenido en una narrativa entretenida, como una serie multiverso (Un formato que narra los hechos de una historia en una serie televisiva permitiéndole al espectador controlar y decidir el desenlace de la narrativa.).</li>
                <li>Producir y transmitir contenido original (series, películas y documentales) basados en las ideas e investigación de los creadores de contenido de TELEBOING.</li>
                <li>Cada televisor, analógico y digital, en cada hogar con acceso 24/7 a TELEBOING mediante decodificador propio.</li>
            </ul>
            <Link to="/upload">
                <button className='creatorpromise__banner__button' >
                    SUBE TU CORTO
                </button>
            </Link>
            </div>
            <div  className='creatorpromise__body__together__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_banner02.webp?alt=media&token=81348bc5-985c-4b2c-9c44-91984195d828' alt='Cada televisor en cada hogar con acceso 24/7 a TELEBOING mediante decodificador propio.' />
            </div>
            </div>

            <div className="creatorpromise__body__expectation" >
            <div  className='creatorpromise__body__expectation__img'>
                <img src='https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_banner05.webp?alt=media&token=e3e5ebb8-c92d-4e81-9a46-cb1e92013747' alt="Promover que tu audiencia migre a TELEBOING." />
            </div>
            <div>
            <p>¿Qué te pedimos como creador de contenidos?</p>
            <ul>
                <li>Contenido exclusivo por un tiempo determinado.</li>
                <li>Acceso a material de Detrás de cámaras.</li>
                <li>Usar tus historias, investigación e ideas originales para producir series, películas y documentales.</li>
                <li>Promover a TELEBOING entre tus seguidores.</li>
            </ul>
            <Link to="/upload">
                <button className='creatorpromise__banner__button' >
                    SUBE TU CORTO
                </button>
            </Link>
            </div>
            </div>

            <div className="creatorpromise__body__closure" >
            <h4>¿Cómo ganamos y retenemos a nuestros suscriptores?</h4>
            <p>Estrategia de generación de contenidos basada en F.O.M.O. (fear of missing out). Las personas no querrán perderse nada que suceda en TELEBOING.</p>
            </div>
            </div>
            </div>
            <div className='creatorpromise__contact' >
                <h4>Si quieres crear contenidos originales para TELEBOING, escríbenos a: teleboing.com@gmail.com o contáctanos por nuestras redes sociales.</h4>
            </div>
            <Social />
            <Copyright />
        </div>
    )
}

export default CreatorPromise;
