import React from "react";
import "./Banner.css";

import {
  Link
} from "react-router-dom";


function Banner() {

    return (
    <div>        
        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover04.webp?alt=media&token=8c45b807-21b5-4e95-920f-190df23ef138"
                )`, 
                backgroundPosition: "top center",
            }}>
        <div className="banner__frame">
            <div className="banner__contents">
            <div>
                <h1>Centro de ayuda</h1>
                <div className="banner__options">
                    <Link to="terms">
                    <button className="banner__options__button">Términos de Uso</button>
                    </Link>

                    <Link to="privacy">
                    <button className="banner__options__button">Declaración de Privacidad</button>
                    </Link>

                    <Link to="creators">
                    <button className="banner__options__button">Relación con los creadores de contenido</button>
                    </Link>

                    <Link to="upload">
                    <button className="banner__options__button">Sube tu corto</button>
                    </Link>

                    {/* <Link to="media">
                    <button className="banner__options__button">Media Center</button>
                    </Link> */}
                </div>
            </div>
            </div>
        </div>
        </header>
    </div>
    );
}

export default Banner;