import React, { useEffect, useState } from 'react';
import "./Nav.css";
import logo from "../assets/teleboing.svg";

function Nav() {

    const [show, handleShow] = useState(false);

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    return (
        <div className={`nav ${show && "nav__black"}`}>
            <div className="nav__contents">
                <div className='nav__logo'>
                    <img
                    style={{ width:"120px" }} 
                    src={logo}
                    alt="teleboing" title="made for fun"
                    />
                </div>
                <div className="nav__buttons" >
                    <a href='https://www.teleboing.com' target={'_blank'} rel="noreferrer">
                    <button >
                    SUSCRÍBETE A TELEBOING
                    </button></a>
                    <a href='https://www.teleboing.com' target={'_blank'} rel="noreferrer">
                    <button >
                    INICIA SESIÓN
                    </button></a>
                </div>
            </div>
        </div>
    )
}

export default Nav;