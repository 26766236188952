import React, { useState } from 'react';
import "./UploadPromise.css";
import Nav from '../components/Nav';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import arrowleft from "../assets/icons/arrow-left.svg";
import arrowdown from "../assets/icons/arrow-down.svg";
import { Link } from "react-router-dom";
import GoToTop from "../GoToTop";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { serverTimestamp } from "firebase/firestore";
import "firebase/compat/firestore";


function UploadPromise() {
    const db = firebaseApp.firestore();
    const [videoUrl, setVideoUrl] = useState("");
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [duration, setDuration] = useState("");
    const [year, setYear] = useState(2022);
    const [premiered, setPremiered] = useState("");
    const [premieredDate, setPremieredDate] = useState();
    const [premieredChannel, setPremieredChannel] = useState("");
    const [synopsis, setSynopsis] = useState("");
    const [sheet, setSheet] = useState("");
    const [cast, setCast] = useState("");
    const [producer, setProducer] = useState("");
    const [direction, setDirection] = useState("");
    const [awards, setAwards] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState("");
    const [posterUrl, setPosterUrl] = useState("");
    const [bgUrl, setBgUrl] = useState("");
    const [genres, setGenres] = useState("");
    const [allowedAge, setAllowedAge] = useState(0);
    const [maxres, setMaxres] = useState("");
    const [language, setLanguage] = useState("");
    const [website, setWebsite] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [titleUrl, setTitleUrl] = useState("");
    const [audience, setAudience] = useState("");

    const [loader, setLoader] = useState(false);

    const handleSubmitTLBNG = async (e) => {
        e.preventDefault();
        setLoader(true);
    
        await db.collection("Upload")
          .add({
            videoUrl: videoUrl,
            title: title,
            type: type,
            duration: duration,
            year: year,
            premiered: premiered,
            premieredDate: premieredDate,
            premieredChannel: premieredChannel,
            synopsis: synopsis,
            sheet: sheet,
            cast: cast,
            producer: producer,
            direction: direction,
            awards: awards,
            city: city,
            country: country,
            thumbnailUrl: thumbnailUrl,
            posterUrl: posterUrl,
            bgUrl: bgUrl,
            genres: genres,
            allowedAge: allowedAge,
            maxres: maxres,
            language: language,
            website: website,
            contactName: contactName,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            titleUrl: titleUrl,
            audience: audience,
            timestamp: serverTimestamp(),
          })
          .then(() => {
            setLoader(false);
            alert("Hemos recibido tu contenido. Te escribiremos pronto. ¡Gracias!");
            window.location.href = 'https://help.teleboing.com';

          })
          .catch((error) => {
            alert(error.message);
            setLoader(false);
          });
    };

    return (
        <div>
            <Nav />
            <div className='uploadpromise'>
            <header className="uploadpromise__banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/help-teleboing.appspot.com/o/resources%2Fcontent%2Fteleboing_cover07.webp?alt=media&token=95547334-c7b3-4892-bbde-6a492bb948cf"
                )`, 
                backgroundPosition: "center center",
            }}>
            <div className='uploadpromise__content'>
                <Link to="/" className="uploadpromise__back" >
                    <img 
                    className='uploadpromise__back__img'
                    src={arrowleft}
                    alt="to TELEBOING helpdesk"
                    />
                    <p className='uploadpromise__back__msg' href="/" >Regresar a la página de inicio de Ayuda</p>
                </Link>
                <h1>Sube tu cortometraje o contenido original</h1>
                <img 
                    className='uploadpromise__back__img'
                    src={arrowdown}
                    alt=""
                />
            </div>
            </header>

            <div className="uploadpromise__body" >

            <div className="uploadpromise__datos">
                <form className="uploadpromise__datos__form" >
                <ul className="uploadpromise__datos__ul__ver">
                    <li>
                    <p>1. Copia y pega la dirección URL de tu contenido Youtube/Vimeo*</p>
                    <input type="url" placeholder='e.g. https://youtu.be/4fOqDvzikPI' value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                    <li>
                    <p>2. Título del contenido*</p>
                    <input type="text" placeholder='e.g. El corto de mi vida' value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </li>

                    <li>
                    <p>3. Tipo de contenido*</p>
                    <input type="text" placeholder='e.g. Cortometraje' value={type} onChange={(e) => setType(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                    <li>
                    <p>4. Duración del contenido*</p>
                    <input type="text" placeholder='00:12:56' value={duration} onChange={(e) => setDuration(e.target.value)} required />
                    </li>

                    <li>
                    <p>5. Año de producción*</p>
                    <input type="number" placeholder='2022' value={year} onChange={(e) => setYear(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                <li>
                    <p>6. ¿Ya fue estrenado?*</p>
                    <input type="text" placeholder='Si / No' value={premiered} onChange={(e) => setPremiered(e.target.value)} required />
                    </li>

                    <li>
                    <p>7. Fecha de estreno</p>
                    <input type="date" placeholder='' value={premieredDate} onChange={(e) => setPremieredDate(e.target.value)} />
                    </li>

                    <li>
                    <p>8. ¿Dónde fue estrenado?</p>
                    <input type="text" placeholder='Youtube, Streaming, Cine, Festival.' value={premieredChannel} onChange={(e) => setPremieredChannel(e.target.value)} />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__ver">
                <li>
                    <p>9. Sinopsis*</p>
                    <input type="text" placeholder='' value={synopsis} onChange={(e) => setSynopsis(e.target.value)} required />
                    </li>

                    <li>
                    <p>10. Ficha técnica*</p>
                    <input type="text" placeholder='' value={sheet} onChange={(e) => setSheet(e.target.value)} required />
                    </li>

                    <li>
                    <p>11. Elenco*</p>
                    <input type="text" placeholder='' value={cast} onChange={(e) => setCast(e.target.value)} required />
                    </li>

                    <li>
                    <p>12. Premios obtenidos</p>
                    <input type="text" placeholder='' value={awards} onChange={(e) => setAwards(e.target.value)} required />
                    </li>

                    <li>
                    <p>13. ¿Cuál es tu audiencia objetivo?</p>
                    <input type="text" placeholder='' value={audience} onChange={(e) => setAudience(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                <li>
                    <p>14. Productor*</p>
                    <input type="text" placeholder='' value={producer} onChange={(e) => setProducer(e.target.value)} required />
                    </li>

                    <li>
                    <p>15. Dirección*</p>
                    <input type="text" placeholder='' value={direction} onChange={(e) => setDirection(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                <li>
                    <p>16. Ciudad</p>
                    <input type="text" placeholder='' value={city} onChange={(e) => setCity(e.target.value)} />
                    </li>

                    <li>
                    <p>17. País*</p>
                    <input type="text" placeholder='' value={country} onChange={(e) => setCountry(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__ver">
                    <li>
                    <p>18. Copia y pega la dirección URL del Thumbnail*</p>
                    <input type="url" placeholder='Orientación horizontal. Resolución mínima: 1920 x 1080 pixels.' value={thumbnailUrl} onChange={(e) => setThumbnailUrl(e.target.value)} required />
                    </li>

                    <li>
                    <p>19. Copia y pega la dirección URL del Logotipo del título*</p>
                    <input type="url" placeholder='Orientación horizontal. Resolución mínima: 800 x 600 pixels.' value={titleUrl} onChange={(e) => setTitleUrl(e.target.value)} required />
                    </li>

                    <li>
                    <p>20. Copia y pega la dirección URL del Poster*</p>
                    <input type="url" placeholder='Orientación vertical. Resolución mínima: 1500 x 2222 pixels.' value={posterUrl} onChange={(e) => setPosterUrl(e.target.value)} required />
                    </li>

                    <li>
                    <p>21. Copia y pega la dirección URL de una escena (foto) destacada del contenido*</p>
                    <input type="url" placeholder='Orientación horizontal. Resolución mínima: 1920 x 1080 pixels.' value={bgUrl} onChange={(e) => setBgUrl(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__hor">
                <li>
                    <p>22. Género*</p>
                    <input type="text" placeholder='Drama, Acción, Suspenso, Thriller, Terror, Ficción, Comedia' value={genres} onChange={(e) => setGenres(e.target.value)} required />
                    </li>

                    <li>
                    <p>23. Edad del público*</p>
                    <input type="number" placeholder='*Permitido para el público, ¿A partir de qué edad?' value={allowedAge} onChange={(e) => setAllowedAge(e.target.value)} required />
                    </li>

                    <li>
                    <p>24. Calidad de imágen*</p>
                    <input type="text" placeholder='720p, HD 1080p, 4K' value={maxres} onChange={(e) => setMaxres(e.target.value)} required />
                    </li>

                    <li>
                    <p>25. Idioma*</p>
                    <input type="text" placeholder='e.g. Español' value={language} onChange={(e) => setLanguage(e.target.value)} required />
                    </li>
                </ul>

                <ul className="uploadpromise__datos__ul__ver">
                <li>
                    <p>26. Sitio web del contenido*</p>
                    <input type="url" placeholder='e.g. https://www.TELEBOING.com' value={website} onChange={(e) => setWebsite(e.target.value)} />
                    </li>

                    <li>
                    <p>27. Nombre del contacto*</p>
                    <input type="text" placeholder='' value={contactName} onChange={(e) => setContactName(e.target.value)} />
                    </li>

                    <li>
                    <p>28. Email del contacto*</p>
                    <input type="email" placeholder='' value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} required />
                    </li>

                    <li>
                    <p>29. Teléfono del contacto*</p>
                    <input type="text" placeholder='(código país) + Teléfono' value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} required />
                    </li>
                </ul>
                
                </form>
            </div>

            <button 
                className="uploadpromise__enviar"
                type='submit' 
                onClick={handleSubmitTLBNG} >Enviar mi contenido
            </button>

            </div>
            </div>
            <div className='uploadpromise__contact' >
                <h4>Si quieres crear contenidos originales para TELEBOING, escríbenos a: teleboing.com@gmail.com o contáctanos por nuestras redes sociales.</h4>
            </div>
            <Social />
            <Copyright />
            <GoToTop />
        </div>
    )
}

export default UploadPromise;
