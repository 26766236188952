import React from 'react';
import "./HomeScreeen.css";
import Nav from '../components/Nav';
import Banner from '../components/Banner';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function HomeScreen() {
    return (
        <div>
            <Nav />
            <Banner />

            <Tabs className="homeScreen__faq" defaultIndex={0}>
                <h3>Preguntas Frecuentes</h3>
                <TabList style={{ color:"grey", fontSize:"1.5rem", lineHeight:"2.2rem", paddingBottom:"20px" }} >
                    <Tab>¿Qué es TELEBOING?</Tab>
                    <Tab>¿Cuánto cuesta acceder a TELEBOING?</Tab>
                    <Tab>¿Dónde puedo ver TELEBOING?</Tab>
                    <Tab>¿Cómo cancelo?</Tab>
                    <Tab>¿Qué puedo ver en TELEBOING?</Tab>
                    <Tab>¿Cuál es el propósito de TELEBOING?</Tab>
                    <Tab>¿En qué países puedo encontrar a TELEBOING?</Tab>
                    <Tab>¿Es bueno TELEBOING para los niños?</Tab>
                </TabList>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>TELEBOING es un servicio de streaming de contenido multiverso que ofrece acceso rápido, sencillo y personalizado a una amplia variedad de cortometrajes, películas, documentales, sesiones de stand-up comedy, obras de teatro, programas originales y series basadas en historias fascinantes, reales y entretenidas cuyo desenlace puedes controlar, y que pueden ser vistas en cualquier pantalla de cualquier dispositivo conectado a internet.
                        Todo lo que quieras ver, sin límites, sin publicidad, a un costo muy accesible. Siempre hay una historia nueva por descubrir, ¡y todos los días, a cada hora, agregamos más contenido original!
                    </p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>Disfruta TELEBOING en tu smartphone, tablet, TV análogo/digital conectado a internet, laptop o dispositivo de streaming, todo por una tarifa plana mensual desde USD $1.99. Sin costos adicionales ni contratos.</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>Disfrútalo donde quieras, cuando quieras y con quien quieras. Inicia sesión en tu cuenta de TELEBOING para ver contenido entretenido multiverso al instante a través de TELEBOING.com desde tu computadora personal o en cualquier dispositivo con conexión a internet, como TV análoga, smart TV, smartphones, tablets, reproductores multimedia y consolas de juegos.</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>TELEBOING es flexible. Sin contratos molestos ni compromisos. Cancela la membresía online con sólo un clic. No hay cargos por cancelación. Empiezas y terminas cuando quieras.</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>TELEBOING tiene miles de cortometrajes, historias únicas, películas independientes, series originales, stand-up comedy y entrevistas exclusivas que son contadas por cineastas, artistas y personas como tú. Tenemos a tus creadores favoritos y a aquellos que aún no conoces en un solo lugar.</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>TELEBOING escucha lo que piensas de cada historia y entiende lo que más te interesa, para producir cortometrajes, series de televisión, películas y documentales que cuenten historias basadas en hechos que son importantes para ti.</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>Actualmente TELEBOING opera únicamente en Perú, pero desde Octubre de 2022 se podrá encontrar también en Colombia, Chile, Argentina y México. Nuestro objetivo es llegar a cada hogar de habla hispana para entregar aquello que tanto se necesita, ¡El control de lo que más te gusta!</p>
                </TabPanel>
                <TabPanel style={{ fontSize:"1.5rem", lineHeight:"2.2rem" }}>
                    <p>Por supuesto que sí. Cada creador tiene como premisa cuidar el lenguaje, el tono y el contenido de cada historia para proteger al espectador y permitirle entender bien cada suceso.
                        Además TELEBOING produce y transmite contenido sólo para niños que tus hijos pueden ver en horarios controlados.
                    </p>
                </TabPanel>
            </Tabs>
            <div className='homescreen__contact' >
                <h4>Si quieres contactarnos, escríbenos a: teleboing.com@gmail.com o envíanos un mensaje por nuestras redes sociales.</h4>
            </div>
            <Social />
            <Copyright />
        </div>
    )
}

export default HomeScreen;
