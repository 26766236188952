import React from 'react';

const FacebookIcon = () => (
    <svg width="32" height="33" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M34.56 0H1.44C0.6435 0 0 0.660892 0 1.47892V35.4941C0 36.3121 0.6435 36.973 1.44 36.973H34.56C35.3565 36.973 36 36.3121 36 35.4941V1.47892C36 0.660892 35.3565 0 34.56 0ZM30.402 10.7915H27.5265C25.272 10.7915 24.8355 11.8914 24.8355 13.509V17.0723H30.2175L29.5155 22.6506H24.8355V36.973H19.224V22.6552H14.5305V17.0723H19.224V12.959C19.224 8.18489 22.0635 5.58292 26.2125 5.58292C28.2015 5.58292 29.907 5.73543 30.4065 5.80476V10.7915H30.402Z"
            fill="#808080"
        />
    </svg>
);

export default FacebookIcon;