import React from "react";
import './App.css';
import HomeScreen from './pages/HomeScreen';
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyDeclaration from "./pages/PrivacyDeclaration";
import MediaCenter from "./pages/MediaCenter";
import CreatorPromise from "./pages/CreatorPromise";
import UploadPromise from "./pages/UploadPromise";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route exact path="terms" element={<TermsOfUse />} />
          <Route exact path="privacy" element={<PrivacyDeclaration />} />
          <Route exact path="media" element={<MediaCenter />} />
          <Route exact path="creators" element={<CreatorPromise />} />
          <Route exact path="upload" element={<UploadPromise />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
