import React from 'react';
import "./PrivacyDeclaration.css";
import Nav from '../components/Nav';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import arrowleft from "../assets/icons/arrow-left.svg";

import { Link } from "react-router-dom";

function PrivacyDeclaration() {
    return (
        <div>
            <Nav />

            <div className='privacy'>

                <Link to="/" className="privacy__back" >
                <img 
                src={arrowleft}
                alt="to TELEBOING helpdesk"
                />
                <p className='privacy__back__msg' href="/" >Regresar a la página de inicio de Ayuda</p>
                </Link>

            <h1>Declaración de privacidad</h1>
            
            <p style={{ paddingBottom:"20px" }} >
            Esta Declaración de privacidad explica nuestras prácticas (y sus elecciones) con respecto a la recopilación, el uso y la divulgación de ciertos datos, incluida su información personal en relación con el servicio de TELEBOING.
            </p>

            <h2>Contacto</h2>
            <ul>
                <li>
                Si tiene preguntas generales sobre su cuenta o cómo ponerse en contacto con nuestro Servicio al Cliente para obtener asistencia, visite nuestro centro de ayuda online, en help.TELEBOING.com o contacte directamente con nosotros mediante nuestras redes sociales. Si tiene alguna pregunta específica respecto de esta declaración de privacidad, o nuestro uso de su información personal, de las cookies o de otras tecnologías similares, escriba a nuestro responsable de Protección de Datos o a la Oficina de Privacidad al email  teleboing.com@gmail.com. (privacy@TELEBOING.com.)

                El responsable del tratamiento de los datos de su información personal es TELEBOING, representada legalmente en Latinoamérica por Insighter del Perú S.A.C. Tenga en cuenta que, si se comunica con nosotros para obtener ayuda, tanto por su seguridad como por la nuestra, podemos pedirle que autentique su identidad antes de atender su solicitud.
                </li>
            </ul>

            <h2>Recopilación de la información</h2>
            <ul>
                <li>
                Recibimos y almacenamos información sobre usted, incluida:
                La información que usted nos proporciona: Recopilamos la información que usted nos brinda, que incluye: Su nombre, email, formas de pago, número de teléfono y otros identificadores que pueda utilizar (como un apodo o sobrenombre). En algunos países, solicitamos un número de identificación gubernamental para la facturación y el cumplimiento de normas impositivas. Esta información se recopila de diferentes maneras, entre ellas, cuando usted la introduce en nuestro servicio, al interactuar con nuestro servicio al cliente, o al participar en encuestas o promociones de marketing;
                La información recopilada cuando usted elige proporcionar calificaciones o preferencias, configura las preferencias (en la sección “Perfil” de nuestra plataforma) o nos brinda información mediante nuestro servicio o de otros medios.
                La información que recopilamos automáticamente: Recopilamos información con relación a usted, el uso de nuestro servicio, sus interacciones con nosotros y nuestra publicidad, además de aquella información relacionada con su red, los dispositivos de red y su computadora o cualquier otro dispositivo compatible con TELEBOING que pudiera utilizar para acceder a nuestro servicio (como los sistemas de videojuegos, Smart TV, dispositivos móviles, decodificadores y otros dispositivos multimedia). Esta información incluye:
                Su actividad en el servicio de TELEBOING, como las selecciones de los títulos, los videos vistos, las consultas de búsquedas y la actividad en el contenido de TELEBOING;
                Sus interacciones con emails y textos, y con nuestras notificaciones automáticas y canales de mensajería online;
                Detalles sobre sus interacciones con el servicio al cliente, como la fecha, hora, razón de la consulta, transcripciones de cualquier conversación en el chat y, si nos llama, su número de teléfono y grabaciones de las llamadas;
                Id. del dispositivo u otros identificadores exclusivos, incluso para su red, los dispositivos de red y los dispositivos compatibles con TELEBOING en su red wifi;
                Identificadores de dispositivos reconfigurables (conocidos como identificadores de publicidad), como los usados en dispositivos móviles, tablets y reproductores multimedia que incluyan tales identificadores;
                Características de software y dispositivo (como tipo y configuración), información de conexión, incluido el tipo (wifi, celular), estadísticas sobre visualización de páginas (por ejemplo, URL de referencia), direcciones IP (que nos pueden informar su ubicación general), datos del navegador e información de registro web estándar;
                La información recopilada a través del uso de cookies, contadores de visitas a la web y otras tecnologías, incluidos los datos de publicidad (por ejemplo, información sobre la disponibilidad y entrega de anuncios, el URL del sitio, así como la fecha y la hora).
                Información de socios: recopilamos información a través de otras empresas con las que usted tiene una relación (“Socios”). Entre estos Socios, podrían incluirse (según los servicios que use): su proveedor de servicios de internet o de TV, u otros proveedores de reproductores multimedia que ponen nuestro servicio a disposición en sus dispositivos; operadores de telefonía móvil u otras empresas que le proveen servicios y, asimismo, recolectan los pagos del servicio de TELEBOING y nos los hacen llegar o proporcionan promociones de prepago para el servicio de TELEBOING; y proveedores de plataformas de asistentes de voz que permiten la interacción con nuestro servicio mediante comandos de voz. La información que los socios nos brindan varía en función de la naturaleza de los servicios del socio, y puede incluir:
                Consultas de búsquedas y comandos relacionados con TELEBOING que se realizan por medio de dispositivos de socios o plataformas de asistentes de voz;
                Información de activación de servicio, como su dirección de email u otra información de contacto;
                Direcciones IP, Id. del dispositivo u otros identificadores exclusivos, como información de interfaz de usuario, facturación y promoción de prepago asociada, usados en la autenticación del usuario, la experiencia del registro del servicio de TELEBOING, el procesamiento del pago del socio, y la presentación del contenido de TELEBOING a usted por medio de secciones de la interfaz de usuario del socio.
                La información que obtenemos de otras fuentes: También obtenemos información de otras fuentes. Protegemos esta información según las prácticas descritas en esta declaración de privacidad, además de las restricciones adicionales impuestas por la fuente de los datos. Estas fuentes pueden variar, y suelen incluir:
                Proveedores de servicios que nos ayudan a identificar una ubicación según la dirección IP a fin de personalizar nuestro servicio y para otros usos conforme esta declaración de privacidad;
                Proveedores de servicios de seguridad que nos brindan información para asegurar nuestros sistemas, prevenir fraudes y ayudarnos a proteger la seguridad de las cuentas de TELEBOING;
                Proveedores de servicios de pago que nos brindan información de pago o saldos, o actualizaciones de esa información, a partir de su relación con usted;
                Proveedores de datos online y offline, que nos suministran datos demográficos agregados, sobre intereses y de publicidad online;
                Fuentes de dominio público, como las publicaciones no privadas de redes sociales y la información disponible por medio de bases de datos públicas que asocian las direcciones IP con los proveedores de servicios de internet (ISP);
                </li>
            </ul>

            <h2>Uso de la información</h2>
            <ul>
                <li>
                Usamos la información recopilada para proporcionar, analizar, administrar, mejorar y personalizar nuestros servicios e iniciativas de marketing, para administrar las recomendaciones de miembros, para procesar su registro, solicitudes y pagos; y para comunicarnos con usted sobre este y otros temas. Por ejemplo, utilizamos dicha información para:

                Determinar la ubicación geográfica general donde se encuentra, ofrecerle contenidos traducidos, brindarle recomendaciones de noticias, reportajes, entrevistas, documentales, películas y series que consideramos que le pueden interesar, determinar su ISP para brindarle apoyo en la solución de problemas de red (también utilizamos información agregada del ISP para fines operativos y comerciales), y ayudarnos a responder con mayor rapidez y eficiencia a las consultas y solicitudes;
                Coordinar con los socios la forma de hacerles llegar el servicio de TELEBOING a los miembros y brindarles información a quienes no son miembros acerca de la disponibilidad del servicio de TELEBOING, según la relación específica que usted tenga con el socio;
                Asegurar nuestros sistemas, prevenir fraudes y ayudarnos a proteger la seguridad de las cuentas de TELEBOING;
                Prevenir, detectar e investigar actividades potencialmente prohibidas o ilegales, como el fraude, y hacer cumplir nuestros términos (por ejemplo, determinar si resulta elegible para ofertas de suscripción de TELEBOING y determinar si a un dispositivo en particular se le permite usar la cuenta de conformidad con nuestros términos de uso);
                Analizar y entender a nuestra audiencia, mejorar nuestro servicio (incluidas nuestras experiencias de IU y desempeño del servicio) y optimizar la selección de contenido, los algoritmos de recomendación y la entrega;
                Comunicarse con usted en relación con nuestro servicio, para poder enviarle noticias sobre TELEBOING, detalles sobre nuevas funciones, contenidos disponibles en TELEBOING, ofertas especiales, anuncios promocionales y encuestas para consumidores, y para ayudarlo con solicitudes operativas, como restablecer su contraseña. Estas comunicaciones podrían enviarse mediante diversos métodos, como el email, las notificaciones automáticas, los mensajes de texto, los canales de mensajería online y las comunicaciones por coincidencia de identificador (descritas a continuación). Consulte la sección “Sus opciones” en esta Declaración de privacidad para saber cómo configurar o cambiar sus preferencias de comunicación.
                Nuestro sistema de recomendaciones hace todo lo posible para predecir lo que tendrá ganas de ver cuando inicie sesión. Sin embargo, nuestro sistema de recomendaciones no infiere ni vincula información sociodemográfica (como el género o la raza) sobre usted.
                </li>
            </ul>

            <h2>Transferencia de la información</h2>
            <ul>
                <li>
                Transferimos su información para determinados propósitos y a terceros, de la siguiente manera:

                Proveedores de servicios: Usamos otras empresas, agentes o contratistas (“Proveedores de servicios”) para que presten servicios en nuestro nombre o para que nos ayuden en la prestación del servicio. Por ejemplo, contratamos Proveedores de servicios para ofrecer servicios de marketing, publicidad, comunicaciones, seguridad, infraestructura y servicios de TI, que personalizan y mejoran nuestro servicio, proporcionan información sobre cuentas bancarias o saldos, procesan las transacciones de las tarjetas de crédito y de otras formas de pago, brindan servicio al cliente, analizan y mejoran los datos (incluida la información relativa a la interacción de nuestros usuarios con nuestro servicio), y procesan y llevan a cabo nuestras encuestas al consumidor. En el transcurso de la prestación de dichos servicios, estos Proveedores de servicios pueden tener acceso a su información personal o de otro tipo. No les brindamos autorización para que usen o divulguen su información personal, a menos que esté relacionada con los servicios que proporcionan (lo que incluye el mantenimiento y la mejora de sus servicios).
                Socios: Según lo antes descrito, usted podría estar relacionado con uno o más de nuestros Socios, en cuyo caso podríamos compartir cierta información con ellos a fin de coordinar la prestación del servicio de TELEBOING a los miembros y también la provisión de información acerca de la disponibilidad del servicio de TELEBOING. Por ejemplo, en función de los servicios de Socio que utilice, podríamos compartir información:
                A fin de facilitar al Socio promociones de prepago o que pueda recolectar el pago del servicio de TELEBOING para hacérnoslo llegar;
                Con Socios encargados de plataformas de asistentes de voz que le permiten interactuar con nuestro servicio mediante comandos de voz;
                Para que el contenido y las funciones disponibles en el servicio de TELEBOING puedan aparecer como sugerencias para usted en la interfaz de usuario del Socio. Para los miembros, estas sugerencias son parte del servicio de TELEBOING y pueden incluir recomendaciones de visualización personalizadas.
                Promociones: Podemos ofrecer promociones o programas conjuntos que requieran el intercambio de información con terceros como condición para participar. Al realizar este tipo de promociones, podremos compartir su nombre y otra información relacionada con la realización del incentivo. Tenga en cuenta que los terceros son responsables de sus propias prácticas de privacidad.
                Protección de TELEBOING y otros: TELEBOING y sus Proveedores de servicios pueden transferir información personal cuando nosotros consideremos o ellos consideren razonable que dicha transferencia sea necesaria para (a) cumplir con cualquier legislación, reglamentación, proceso legal o solicitud gubernamental correspondiente; (b) hacer cumplir términos de uso correspondientes, incluida la investigación de posibles infracciones; (c) detectar, prevenir o abordar actividades ilegales o presuntamente ilegales (incluidas las de pago fraudulento), problemas de seguridad o técnicos; o (d) proteger de daño contra los derechos, la propiedad o la seguridad de TELEBOING, sus usuarios o el público, según lo requiera o permita la ley.
                Transferencias de negocios: En relación con una reorganización, reestructuración, fusión o venta, o cualquier otra transferencia de bienes, nosotros transferiremos la información, incluida la personal, a condición de que el destinatario se comprometa a respetar su información personal de conformidad con nuestra Declaración de privacidad.
                Cuando compartamos información personal con otros países, nos aseguraremos de que dicha información se transfiera de acuerdo con esta Declaración de privacidad y con las leyes correspondientes sobre protección de datos.

                Usted también puede elegir la transferencia de su información de las siguientes maneras:

                Ciertas áreas de nuestro servicio pueden incluir una herramienta que le permita compartir información por email, mensaje de texto y apps sociales o de otro tipo mediante los clientes y apps en su dispositivo inteligente; y
                Plugins o complementos sociales y otras tecnologías similares que le permiten compartir información; 
                Las mismas redes sociales manejan estos plugins y estas apps, y están sujetos a sus términos de uso y políticas de privacidad.
                </li>
            </ul>

            <h2>Acceso a la cuenta y los perfiles</h2>
            <ul>
                <li>
                Función “Recordarme”: Para facilitar el acceso a su cuenta, puede usar la función “Recordarme en este dispositivo” cuando inicia sesión en el sitio web. Esta función usa tecnología que nos permite brindar acceso directo a la cuenta y ayudar a administrar el servicio de TELEBOING sin necesidad de solicitar una contraseña u otra identificación del usuario cada vez que el navegador accede al servicio.
                Permitirles a terceros el acceso a su cuenta: Si comparte o permite que otros tengan acceso a su cuenta, podrán ver el contenido que ha visto, calificaciones, información de la cuenta (incluida su dirección de email u otra información de la sección “Perfil” de nuestro sitio web). Esto es así incluso si usa nuestra función de perfiles. Es posible que tenga la opción de utilizar el inicio de sesión de cuenta transparente por medio de la app móvil de TELEBOING, que permite iniciar sesión en la app de TELEBOING en smart TV, decodificadores y otros reproductores multimedia en la red wifi a la que está conectado. Si utiliza esa función, esos dispositivos permanecerán registrados en su cuenta a menos que luego cierre sesión en esos dispositivos.
                Perfiles: Los perfiles permiten que cada usuario tenga su propia experiencia personalizada de TELEBOING, de acuerdo con las noticias, los reportajes, las entrevistas, los documentales, las series y las películas que le interesen, además de historiales de visualización separados. Tenga en cuenta que los perfiles estarán disponibles para cualquiera que use su cuenta de TELEBOING, así que toda persona que tenga acceso a su cuenta de TELEBOING puede navegar, usar, editar o borrar los perfiles. Esto debe ser explicado a las otras personas que tengan acceso a su cuenta; si no desea que usen o modifiquen su perfil, asegúrese de que estén al tanto de dicha situación. A los usuarios de perfiles se les podría ofrecer la oportunidad de agregar una dirección de email, un número de teléfono u otra información al perfil y se les brindará aviso de la recopilación y el uso en el momento en que se requiera dicha información.
                Eliminar el acceso a su cuenta de TELEBOING: Para eliminar el acceso a su cuenta de TELEBOING desde sus dispositivos, visite la sección “Perfil” de nuestra plataforma, elija “Salir de TELEBOING” o “Cerrar sesión en todos los dispositivos”, y siga las instrucciones para desactivar sus dispositivos (tenga en cuenta que la desactivación puede no ser inmediata). Siempre que sea posible, los usuarios de dispositivos públicos o compartidos deben cerrar sesión al finalizar cada visita. Si vende o devuelve una computadora o un dispositivo listo para acceder a TELEBOING, debería cerrar sesión y desactivar el dispositivo antes de hacerlo. Si usted no mantiene de forma segura su contraseña o su dispositivo, o no cierra sesión o desactiva su dispositivo, los usuarios posteriores podrán acceder a la información de su cuenta, incluida su información personal.
                Sus opciones
                Email y mensajes de texto. Si ya no desea recibir ciertas comunicaciones nuestras por medio de email o mensaje de texto, simplemente acceda a las opciones de configuración de comunicaciones en la sección “Perfil” de nuestra plataforma y quite la marca correspondiente para dejar de recibir emails. Tenga en cuenta que no podrá dejar de recibir determinados tipos de correspondencia vinculados con el servicio, como los mensajes relacionados con las transacciones de su cuenta.

                Notificaciones automáticas. Puede optar por recibir las notificaciones automáticas móviles de TELEBOING. En ciertos sistemas operativos, se le inscribirá automáticamente en las notificaciones. Si posteriormente decide que ya no desea recibir estas notificaciones, puede utilizar las funciones de configuración de su dispositivo móvil para desactivarlas. También ofrecemos notificaciones automáticas en ciertos navegadores web. Si acepta recibir esas notificaciones y posteriormente decide que ya no desea recibirlas, puede utilizar las funciones de configuración de su navegador para desactivarlas.

                WhatsApp. Si habilitó los mensajes de WhatsApp en relación con su cuenta o perfil de TELEBOING y ya no desea recibirlos, puede configurar WhatsApp para que los bloquee.

                Anuncios basados en intereses. Los anuncios basados en intereses son anuncios online que se adaptan a sus posibles intereses, que se determinan a partir de su uso de diversas apps y sitios web en internet. Si usa un navegador, las cookies y los contadores de visitas a la web permiten recopilar información que ayuda a identificar sus intereses probables. Si usa un dispositivo móvil, una tablet o un reproductor multimedia que incluya un identificador de dispositivo reconfigurable, tal identificador sirve para identificar sus intereses probables. Para obtener más información sobre los anuncios basados en intereses de TELEBOING, consulte la sección “Cookies y publicidad de internet” (más adelante en este documento).

                Comunicaciones por coincidencia de identificador. Algunos sitios de terceros y apps nos permiten enviarles a nuestros usuarios promociones online sobre nuestros títulos y servicios enviando un identificador de protección de privacidad al tercero. El identificador de protección de privacidad indica que convertimos la información original (como una dirección de email o un número de teléfono) en un valor a fin de evitar la divulgación de tal información. El tercero compara el identificador de protección de privacidad con los identificadores de su base de datos, y se establece una coincidencia solo si usted usó el mismo identificador (como una dirección de email) tanto con TELEBOING como con el tercero. Si hay una coincidencia, TELEBOING puede optar por enviarle una comunicación promocional específica a través de esa app o sitio de un tercero, y puede optimizar y medir mejor la efectividad de la publicidad online.
                </li>
            </ul>

            <h2>Su información y sus derechos</h2>
            <ul>
                <li>
                Puede solicitar acceso a su información personal, o corregir o actualizar la información personal desactualizada o inexacta que tenemos sobre usted. También puede solicitar que eliminemos la información personal que tenemos sobre usted.

                Cuando visite la sección “Perfil” de nuestra plataforma, tiene la posibilidad de acceder y actualizar una amplia variedad de información acerca de su cuenta, incluida la información de contacto, su información de pago de TELEBOING y demás información relativa a su cuenta (como el contenido que ha visto y calificado). Tiene que haber iniciado sesión para acceder a la sección “Perfil”.

                Para otras solicitudes, o si tiene alguna pregunta en relación con nuestras prácticas de privacidad, escriba a nuestro responsable de Protección de Datos o a la Oficina de Privacidad al email teleboing.com@gmail.com.

                Podemos rechazar solicitudes que no sean proporcionales o que la ley no exija, incluidas aquellas que resulten extremadamente poco prácticas, que puedan requerir un esfuerzo técnico desproporcionado o que podrían exponernos a riesgos operativos, como fraudes en las pruebas gratuitas. Podemos retener la información requerida o permitida por las leyes y regulaciones aplicables, incluso para cumplir con sus selecciones, para nuestros fines de facturación o registros, y para cumplir con los propósitos descritos en esta Declaración de privacidad. Tomamos las medidas razonables para destruir o anonimizar la información personal de forma segura una vez que ya no se la necesita.
                </li>
            </ul>

            <h2>Seguridad</h2>
            <ul>
                <li>
                Contamos con medidas administrativas, logísticas, físicas y de gestión razonables para proteger su información personal contra pérdidas, robos y acceso no autorizado, uso y modificación. Estas medidas tienen como objetivo brindar un nivel de seguridad adecuado a los riesgos de tratar la información personal.
                </li>
            </ul>

            <h2>Otros sitios web, plataformas y apps</h2>
            <ul>
                <li>
                El servicio de TELEBOING puede recibirse o utilizar funciones (como controles de voz) operadas por plataformas de terceros, o contener enlaces a sitios operados por terceros que pueden tener políticas respecto del manejo de información diferentes de las nuestras. Por ejemplo, usted puede acceder al servicio de TELEBOING por medio de plataformas como sistemas de videojuegos, Smart TV, dispositivos móviles, decodificadores y una variedad de dispositivos con conexión a internet. Estos sitios web y plataformas tienen sus propias políticas de privacidad y datos, declaraciones de privacidad, avisos y términos de uso, que le recomendamos leer con atención. Además, es probable que encuentre apps de terceros que interactúen con el servicio de TELEBOING.
                </li>
            </ul>

            <h2>Menores</h2>
            <ul>
                <li>
                Debe tener, al menos, 18 años de edad para poder suscribirse al servicio de TELEBOING. Los menores solo pueden utilizar el servicio con la participación, supervisión y aprobación de un padre o tutor legal.
                </li>
            </ul>

            <h2>Cambios a esta Declaración de privacidad</h2>
            <ul>
                <li>
                Actualizaremos esta Declaración de privacidad cuando sea necesario, de acuerdo con los cambios en los requisitos legales, regulatorios u operativos. Avisaremos de tales cambios (incluida la información sobre la fecha de implementación) en conformidad con la ley. Si sigue utilizando el servicio de TELEBOING después de que las actualizaciones entren en vigor, se interpretará que ha leído y aceptado (según corresponda) los cambios. Si prefiere no reconocer o aceptar alguna de las actualizaciones de esta Declaración de privacidad, puede cancelar y dejar de usar el servicio de TELEBOING. Para saber cuándo fue la última actualización de esta Declaración de privacidad, consulte la sección “Última actualización” más abajo.
                </li>
            </ul>

            <h2>Cookies y publicidad de internet</h2>
            <ul>
                <li>
                Nosotros y nuestros Proveedores de servicios utilizamos cookies y otras tecnologías (como contadores de visitas a la web), además de identificadores de dispositivos reconfigurables, por diferentes razones. Queremos que esté informado sobre el uso que hacemos de estas tecnologías, de modo que esta sección explica los tipos de tecnología que usamos, cómo se comportan y cuáles son sus opciones de uso.

                Cookies y tecnologías similares, contadores de visitas a la web e identificadores de dispositivos reconfigurables

                Las cookies son archivos de datos pequeños que usualmente se almacenan en su dispositivo cuando navega y utiliza sitios web y otros servicios online. Utilizamos otras tecnologías, como almacenamiento en el navegador y plugins (p. ej., HTML5, IndexedDB y WebSQL). Al igual que las cookies, estas otras tecnologías pueden almacenar pequeñas cantidades de datos en su dispositivo. Los contadores de visitas a la web (también conocidos como “clear.gif” o “etiqueta de píxel”) a menudo funcionan junto con las cookies. En muchos casos, rechazar las cookies reducirá la eficacia de los contadores de visitas a la web asociados con tales cookies.

                Si usa la app de TELEBOING en un dispositivo móvil, tablet o reproductor multimedia, es posible que recopilemos un identificador de dispositivo reconfigurable de su dispositivo. Los identificadores de dispositivos reconfigurables (también llamados identificadores de anuncios) son similares a las cookies y se encuentran en varios dispositivos móviles y tablets (por ejemplo, el “identificador para anunciantes”, o IDFA, de los dispositivos con Apple iOS y el “identificador de anuncios de Google” en los dispositivos con Android), y en algunos reproductores multimedia. Al igual que las cookies, los identificadores de dispositivos reconfigurables permiten ofrecer publicidad online más relevante y con fines de análisis y de optimización.
                </li>
            </ul>

            <h2>¿Por qué TELEBOING utiliza estas tecnologías?</h2>
            <ul>
                <li>
                Utilizamos este tipo de tecnologías por varias razones: para facilitar el acceso a nuestros servicios recordándole cuando regresa; para proporcionar, analizar, comprender y mejorar el uso de nuestros servicios; para hacer cumplir nuestros términos y prevenir el fraude; para mejorar el rendimiento del sitio, monitorear el tráfico de visitantes y las acciones en nuestro sitio; y para entregar y personalizar nuestro marketing o publicidad; y para entender las interacciones con nuestros emails, marketing y anuncios online en sitios de terceros.

                Para ayudarle a comprender mejor cómo utilizamos las cookies y los identificadores de dispositivos reconfigurables, consulte la información que aparece a continuación:

                Cookies esenciales: Estas cookies son estrictamente necesarias para nuestro sitio web o servicio online. Por ejemplo, nosotros y nuestros Proveedores de servicios podemos usar estas cookies para autenticar e identificar a nuestros miembros cuando utilizan nuestros sitios web y apps, de modo que podamos proveerles nuestros servicios. Además, nos ayudan a aplicar nuestros Términos de uso, evitar fraudes y mantener la seguridad del servicio.
                Cookies de rendimiento y funcionamiento: Estas cookies nos ayudan a personalizar y mejorar su experiencia online con TELEBOING. Por ejemplo, nos ayudan a recordar sus preferencias para que no tenga que volver a escribir la información ya provista (por ejemplo, durante el inicio de sesión). También utilizamos estas cookies para recopilar información (por ejemplo, páginas populares, tarifas de cambio, patrones de visualización, proporción de clics y otra información) sobre el uso que hacen los visitantes del servicio de TELEBOING, de modo que podamos mejorarlo y personalizarlo, así como nuestro sitio web, y llevar a cabo investigación de mercado. Si se borran estas cookies, el funcionamiento de nuestro servicio puede verse limitado.
                Cookies de publicidad e identificadores de dispositivos reconfigurables: Estas cookies y estos identificadores de dispositivos reconfigurables utilizan información sobre su visita a este y otros sitios web o apps, y su respuesta a anuncios comerciales e emails, para presentar anuncios más relevantes para usted y con fines de análisis y de optimización. Este tipo de anuncios comerciales se llama “publicidad basada en el interés del usuario”. Las cookies de anunciante asociadas con nuestro servicio pertenecen a nuestros Proveedores de servicios.
                En relación con nuestro uso de estas tecnologías, algunos de los sitios web y apps en los que nos anunciamos, así como las empresas de tecnología publicitaria que utilizamos para comprar, entregar, optimizar y/o medir nuestros anuncios (en conjunto “Socios anunciantes”), pueden recibir información limitada de nosotros como parte de nuestra campaña de segmentación, medición y optimización (por ejemplo, los pasos completados en la suscripción y la visita al sitio o la información de apertura/instalación de apps).

                Los usos comunes de este tipo de información tienen por objeto evaluar la efectividad y optimizar las campañas publicitarias, lo que les permite a los socios anunciantes ver cuando alguien que vio un anuncio luego se suscribió a nuestro servicio. Otro uso común es asegurarse de que no publicamos anuncios para conseguir suscriptores al servicio de TELEBOING si ya son usuarios de TELEBOING.

                TELEBOING utiliza medidas contractuales y técnicas diseñadas para evitar que los socios anunciantes accedan a la información relativa a las selecciones específicas de títulos que usted realice, las URL a las que acceda o contenido que haya visto en nuestro servicio. No compartimos información sobre selecciones de títulos o contenido que haya visto en nuestro servicio.
                </li>
            </ul>

            <h3>Última actualización: 19 de junio de 2022</h3>

            </div>

            <Social />
            <Copyright />
        </div>
    )
}

export default PrivacyDeclaration;
