import React from 'react';
import "./TermsOfUse.css";
import Nav from '../components/Nav';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import arrowleft from "../assets/icons/arrow-left.svg";

import { Link } from "react-router-dom";

function TermsOfUse() {
    return (
        <div>
            <Nav />

            <div className='termsofuse'>

                <Link to="/" className="termsofuse__back" >
                <img 
                src={arrowleft}
                alt="to TELEBOING helpdesk"
                />
                <p className='termsofuse__back__msg' href="/" >Regresar a la página de inicio de Ayuda</p>
                </Link>

            <h1>Términos de uso de TELEBOING</h1>
            <p>
            TELEBOING brinda un servicio de suscripción personalizado que permite a nuestros miembros acceder a contenido original de entretenimiento e información (el “contenido de TELEBOING”) a través de internet en ciertas TV, computadoras y otros dispositivos conectados a internet (“dispositivos listos para acceder a TELEBOING”).
            </p>

            <p style={{ paddingBottom:"20px" }} >Los presentes Términos de uso rigen la utilización que haga de nuestro servicio. Según se utilicen en estos Términos de uso, las frases “el servicio de TELEBOING”, “nuestro servicio” o “el servicio” se refieren al servicio personalizado brindado por TELEBOING para descubrir y acceder al contenido de TELEBOING, incluidas todas las características y funcionalidades, recomendaciones y críticas, nuestros sitios web y las interfaces de usuario, además de todo el contenido y software asociado a nuestro servicio.</p>

            <h2>Membresía</h2>

            <ul>
                <li>
                <span>1.1.</span> Su membresía de TELEBOING continuará hasta que la cancele. Para usar el servicio de TELEBOING, debe tener acceso a internet y un dispositivo listo para acceder a TELEBOING, y proporcionar una o más formas de pago. “Forma de pago” significa una forma de pago actual, válida y aceptada, que pueda actualizarse periódicamente y que admita pagos a través de terceros. A menos que cancele su membresía antes de la fecha de facturación, nos autoriza a cobrarle la membresía del siguiente ciclo de facturación a su Forma de pago (ver “Cancelación” a continuación).
                </li>
                <li><span>1.2.</span> Podemos ofrecer diversos planes de membresía, incluidas las membresías que se ofrecen a través de terceros junto con la provisión de sus propios productos y servicios. No somos responsables por los productos y servicios provistos por dichos terceros. Ciertas membresías tienen condiciones y limitaciones diferentes que se aclaran en el acuerdo de suscripción o en otras comunicaciones. Podrá encontrar la información específica sobre su membresía de TELEBOING visitando el sitio web TELEBOING.com y haciendo clic en el vínculo “Perfil”, disponible en la parte superior de las páginas bajo su nombre de perfil.
                Ofertas promocionales. Ocasionalmente, podríamos hacer ofertas especiales de promociones, planes o membresías (“Ofertas”). TELEBOING determina los requisitos para acceder a la oferta a su exclusivo criterio, y nos reservamos el derecho de revocar una oferta y suspender su cuenta si determinamos que usted no es elegible. Los miembros en hogares con una membresía de TELEBOING existente o reciente podrían no ser elegibles para ciertas ofertas de introducción. Podemos utilizar información como el Id. del dispositivo, la forma de pago o la dirección de email de la cuenta utilizada con una membresía de TELEBOING existente o reciente para determinar la elegibilidad de la oferta. Los requisitos de elegibilidad y otras limitaciones y condiciones se le informarán cuando se suscriba a la oferta o en otras comunicaciones puestas a su disposición.
                </li>
            </ul>


            <h2>Facturación y cancelación</h2>

            <ul>
                <li><span>2.1.</span> Ciclo de facturación. Los cargos de membresía por el servicio de TELEBOING y cualquier otro cargo en el que incurra en relación con el uso que haga del servicio de TELEBOING, como impuestos y posibles gastos de transacción, se cobrarán a su forma de pago en la fecha de pago específica indicada en la página “Perfil”. La duración de su ciclo de facturación dependerá del tipo de membresía que haya seleccionado al suscribirse al servicio. En ciertos casos, su fecha de pago podría cambiar, por ejemplo, si no se pudo hacer el cobro con su forma de pago satisfactoriamente, cuando cambie su plan de suscripción, o si su membresía pagada comenzó un día que no está incluido en un determinado mes. Visite el sitio web TELEBOING.com y haga clic en el vínculo “Información de facturación”, en la página “Perfil” para ver su próxima fecha de pago. Podemos autorizar su forma de pago antes del cobro relacionado con la membresía o el servicio a través de varios métodos, e incluso autorizarla para que dure aproximadamente un mes de servicio a partir de su registro. Si se suscribió a TELEBOING usando su cuenta con un tercero como forma de pago, puede encontrar la información sobre la facturación de su membresía de TELEBOING en su cuenta correspondiente del servicio del tercero.
                </li>

                <li><span>2.2.</span> Formas de pago. Para usar el servicio de TELEBOING debe proveer una forma de pago o más. Nos autoriza a hacer cargos a cualquier forma de pago asociada con su cuenta en caso del rechazo o indisponibilidad de su forma de pago principal. Cualquier cargo pendiente le corresponderá a usted. Si el pago no se pudiera hacer satisfactoriamente, debido a la fecha de vencimiento, la falta de fondos o si usted no actualiza la información de su forma de pago o cancela su cuenta, podemos suspender su acceso al servicio hasta que obtengamos una forma de pago válida. Para algunas formas de pago, el emisor puede cobrarle ciertos cargos, como cargos de transacción extranjera u otros cargos relacionados con el procesamiento de su forma de pago. Los impuestos locales varían en función de la forma de pago usada. Consulte con el proveedor de servicios de su forma de pago para obtener información.
                </li>

                <li><span>2.3.</span> Actualización de sus Formas de pago. Se puede actualizar su forma de pago en "Perfil". También se puede actualizar la forma de pago con la información provista por el proveedor de servicios de pago correspondiente. Luego de cualquier actualización, nos autoriza a hacer cargos a las formas de pago correspondientes.
                </li>

                <li><span>2.4.</span> Cancelación. Puede cancelar la membresía de TELEBOING en cualquier momento, y continuará teniendo acceso al servicio hasta el final de su período de facturación. En la medida permitida por la ley aplicable, los pagos no son reembolsables y no se otorgarán reembolsos ni créditos por los periodos de membresía utilizados parcialmente o por el contenido de TELEBOING no usado. Para cancelar, visite la página “Perfil” y siga las instrucciones. Si cancela su membresía, su cuenta se cerrará automáticamente al final de su período de facturación actual. Para ver cuándo se cerrará su cuenta, haga clic en "Información de facturación" en la página "Perfil". Si se suscribió a TELEBOING mediante su cuenta con un tercero como forma de pago y desea cancelar su membresía de TELEBOING en cualquier momento, es posible que tenga que hacerlo a través de dicho tercero, ya sea, al visitar su cuenta con el tercero correspondiente para desactivar su renovación automática o al cancelar la suscripción al servicio de TELEBOING a través de ese tercero.
                </li>

                <li><span>2.5.</span> Cambios en el precio y Planes de suscripción. Podemos cambiar nuestros planes de suscripción y el precio de nuestro servicio de vez en cuando. Sin embargo, cualquier cambio en los precios o en los planes de suscripción se aplicará no antes de los 30 días siguientes a la notificación.
                </li>
            </ul>

            <h2>Servicio de TELEBOING</h2>

            <ul>
                <li><span>3.1.</span> Debe tener, al menos, 18 años de edad, o la mayoría de edad en su provincia, territorio o país, para ser miembro del servicio de TELEBOING. Los menores solamente pueden usar el servicio bajo la supervisión de un adulto.
                </li>

                <li><span>3.2.</span> El servicio de TELEBOING y todo el contenido al que se accede en él son solo para uso personal, no comercial, y no debe compartirse con personas que no sean miembros de su hogar. Durante la membresía de TELEBOING, le otorgamos un derecho limitado, no exclusivo e intransferible para acceder al servicio y al contenido de TELEBOING. Más allá de esto, no se le transferirá ningún otro derecho, título o interés. Usted acepta que no usará el servicio para presentaciones públicas.
                </li>

                <li><span>3.3.</span> Usted puede acceder al contenido de TELEBOING principalmente en el país donde estableció su cuenta y solo en los lugares geográficos en los que ofrecemos nuestro servicio y donde se tenga licencia para ese contenido. El contenido que puede estar disponible puede variar según la ubicación geográfica y cambia periódicamente. La cantidad de dispositivos en los que puede ver simultáneamente depende del plan de suscripción elegido y se especifica en la página “Perfil”.
                </li>

                <li><span>3.4.</span> TELEBOING actualiza el servicio continuamente, incluido su catálogo de contenido. Además, probamos regularmente varios aspectos de nuestro servicio, tales como nuestros sitios web, las interfaces de usuario, las funciones promocionales y la disponibilidad de contenido de TELEBOING. Usted puede desactivar en cualquier momento su participación en pruebas visitando la página "Perfil" y cambiando la configuración de "Participación en pruebas".
                </li>

                <li><span>3.5.</span> Parte del contenido de TELEBOING está disponible para descarga temporal y visualización offline en ciertos dispositivos compatibles. Se aplican restricciones, incluidas las restricciones a la cantidad de títulos offline por cada cuenta, la cantidad máxima de dispositivos que pueden contener títulos offline, el período en el cual deberá comenzar a ver los títulos offline y cuánto tiempo permanecerán accesibles los títulos offline. Es posible que algunos títulos offline no se puedan reproducir en determinados países y que, si se conecta en un país en el que no se puede hacer streaming de ese título offline, dicho título no se pueda reproducir mientras esté en ese país.
                </li>

                <li><span>3.6.</span> Acepta usar el servicio de TELEBOING, incluidas todas las características y funcionalidades asociadas con éste, bajo conformidad con todas las leyes, normas y reglamentaciones vigentes, o cualquier otra restricción al uso del servicio o sus contenidos. Usted acepta no archivar, reproducir, distribuir, modificar, mostrar, ejecutar, publicar, otorgar licencias, crear obras derivadas basadas en el servicio, u ofrecer en venta, o usar (a excepción de que se autorice explícitamente en estos Términos de uso) contenido e información contenida en u obtenida a través del servicio de TELEBOING. Usted también acepta: no evitar, eliminar, alterar, desactivar, interferir con o burlar las protecciones de contenido del servicio de TELEBOING; no usar ningún robot, spider, scraper u otra forma automatizada para acceder al servicio de TELEBOING; ni descompilar, realizar ingeniería inversa, desarmar el software u otro producto o proceso a los que se acceda a través del servicio de TELEBOING; no introducir de alguna manera un código o producto o manipular el contenido del servicio de TELEBOING; ni usar método alguno de análisis, extracción u obtención de datos. Asimismo, se compromete a no subir, publicar, enviar por email ni transmitir de cualquier otra forma ningún material diseñado para interrumpir, destruir o limitar la funcionalidad del software de computación, hardware o equipos de telecomunicaciones asociados con el servicio de TELEBOING, incluido material que contenga virus de software o cualquier otro código, archivos o programas. Podríamos terminar o restringir su uso de nuestro servicio si usted viola cualquiera de los términos de uso o usa el servicio de forma ilegal o fraudulenta.
                </li>

                <li><span>3.7.</span> La velocidad de carga y la calidad de la imagen del contenido de TELEBOING puede variar de dispositivo a dispositivo y puede verse afectada por diversos factores, tales como la ubicación, el ancho de banda disponible o la velocidad de la conexión a internet. La disponibilidad del contenido en alta definición (HD), ultra alta definición (Ultra HD) y alto rango dinámico (HDR) depende de su servicio de internet y del dispositivo en uso. No todo el contenido está disponible en todos los formatos, como HD, Ultra HD o HDR, ni todos los planes de suscripción le permiten recibir contenido en todos los formatos. La configuración de reproducción predeterminada en las redes celulares excluye contenido HD, Ultra HD y HDR. La velocidad mínima de conexión para la calidad SD es de 1.0 Mb/s. Sin embargo, recomendamos una conexión más rápida para mejorar la calidad de video. Se recomienda una velocidad de descarga de, al menos, 3.0 Mb/s por transmisión para recibir contenido HD (definido como una resolución de 720p o más alta). Se recomienda una velocidad de descarga de, al menos, 15.0 Mb/s por transmisión para recibir contenido Ultra HD (definido como una resolución de 4K o más alta). Todos los cargos de acceso a internet correrán por su cuenta. Solicite a su proveedor de internet información acerca de los posibles cargos de consumo de datos por uso de internet. El tiempo que lleva comenzar a ver contenido de TELEBOING variará según diversos factores, incluido el lugar donde se encuentra, el ancho de banda disponible en ese momento, el contenido que haya seleccionado y la configuración de su dispositivo listo para TELEBOING.
                </li>

                <li><span>3.8.</span> El software de TELEBOING es desarrollado por TELEBOING.com o a pedido de TELEBOING.com, y solo puede utilizarse para hacer transmisiones autorizadas y para acceder al contenido de TELEBOING a través de dispositivos listos para acceder a TELEBOING. Este software puede variar según el dispositivo y el medio, y la funcionalidad y las funciones también pueden variar de un dispositivo a otro. Usted reconoce que el uso del servicio puede requerir software de terceros que esté sujeto a licencias de terceros. Usted acepta que puede recibir automáticamente versiones actualizadas del software de TELEBOING y el software relacionado de terceros.
                Contraseñas y acceso a la cuenta. El miembro que creó la cuenta de TELEBOING y al que se le facturan los cargos a través de su forma de pago (el “Titular de la cuenta”) es responsable de cualquier actividad que ocurra en la cuenta de TELEBOING. Para mantener el control sobre la cuenta y evitar que alguien acceda a la cuenta (que incluiría información sobre el historial de visualización de la cuenta), el titular de la cuenta debería mantener el control sobre los dispositivos listos para acceder a TELEBOING que se utilizan para acceder al servicio y no revelar a nadie la contraseña ni la información de la forma de pago asociada con la cuenta. Es su responsabilidad actualizar y mantener la exactitud de la información personal que nos brinda respecto de su cuenta. Podemos decidir cancelar su cuenta o suspenderla para protegerlo a usted, a TELEBOING o a nuestros socios contra el robo de identidad u otra actividad fraudulenta.
                Exclusión de garantías y limitaciones a la responsabilidad. El servicio de TELEBOING se ofrece “tal cual”, sin garantía ni condición. En particular, nuestro servicio no se declara sin interrupciones ni sin errores. Usted renuncia a todos los daños especiales, indirectos y consecuentes contra nosotros. Estos términos no limitarán las garantías no renunciables ni los derechos de protección al consumidor a los que usted tenga derecho bajo las leyes imperativas de su país de residencia.
                Renuncia a la acción colectiva. EN LA MEDIDA EN QUE LAS LEYES APLICABLES LO PERMITAN, USTED Y TELEBOING ACUERDAN QUE CADA UNO PUEDE PRESENTAR RECLAMOS CONTRA LA OTRA PARTE SOLO EN NOMBRE PROPIO, Y NO COMO ACTORA O PARTE DE UN GRUPO EN UNA ACCIÓN COLECTIVA O REPRESENTATIVA. Además, si la ley aplicable lo permite, a menos que tanto usted como TELEBOING acuerden lo contrario, el tribunal no podrá acumular las causas de más de una persona con su causa o, de lo contrario, no podrá presidir ninguna acción representativa o colectiva.
                </li>
            </ul>

            <h2>Disposiciones varias</h2>
            <ul>
                <li><span>4.1.</span> Ley vigente. Estos términos de uso se regirán e interpretarán de conformidad con las leyes de la República del Perú, sin perjuicio de cualquier disposición de derecho internacional privado.
                </li>

                <li><span>4.2.</span> Material no solicitado. TELEBOING no acepta materiales ni ideas no solicitados para su contenido, y no es responsable por la similitud entre los contenidos o la programación de cualquier medio con los materiales o ideas transmitidos a TELEBOING. Así mismo no se responsabiliza por el contenido que los creadores presenten en sus espacios.
                </li>

                <li><span>4.3.</span> Servicio al cliente. Si necesita obtener más información sobre nuestro servicio y sus funciones, o si necesita asistencia con su cuenta, visite el centro de ayuda de TELEBOING o contacte directamente con nosotros a través de nuestras redes sociales, a los que puede acceder a través del sitio web TELEBOING.com. En algunos casos, el Servicio al cliente podrá ayudarlo mejor utilizando una herramienta de asistencia de acceso remoto con el que se accede completamente a su computadora. Si no desea que tengamos este acceso, usted no debería autorizar la asistencia a través de la herramienta de acceso remoto y nosotros le ayudaremos de otra forma. En el caso en que haya un conflicto entre estos términos de uso y la información proporcionada por el Servicio al Cliente u otras secciones de nuestros sitios web, estos términos de uso dirimirán cualquier diferencia.
                </li>

                <li><span>4.4.</span> Continuación de vigencia. Si alguna de las disposiciones de estos términos de uso es declarada nula, ilegal o inaplicable, la validez, legalidad y aplicación de las restantes disposiciones continuarán en plena vigencia.
                </li>

                <li><span>4.5.</span> Cambios en los términos de uso y cesión. TELEBOING puede cambiar estos términos de uso cuando sea necesario. Le informaremos con al menos 30 días de anticipación si estos cambios se aplican a usted. Podemos ceder o transferir nuestro acuerdo con usted, incluidos nuestros derechos asociados y nuestras obligaciones en cualquier momento y usted acepta cooperar con nosotros en relación con dicha cesión o transferencia.
                </li>

                <li><span>4.6.</span> Comunicaciones electrónicas. Le enviaremos la información relativa a su cuenta (por ejemplo, las autorizaciones de pago, las facturas, los cambios de contraseña o de la forma de pago, los mensajes de confirmación, los avisos) de manera electrónica únicamente, por ejemplo, mediante emails a la dirección de email proporcionada durante el registro.
                </li>
            </ul>

            <h3>Última actualización: 19 de junio de 2022</h3>

            </div>

            <Social />
            <Copyright />
        </div>
    )
}

export default TermsOfUse;
