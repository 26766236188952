import React from 'react';

const TwitterIcon = () => (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36 3.47145C34.6761 4.05807 33.2539 4.45445 31.7588 4.63361C33.3015 3.71052 34.4556 2.2577 35.0059 0.546228C33.5565 1.40713 31.9702 2.01311 30.316 2.33783C29.2036 1.15008 27.7302 0.362829 26.1245 0.0982879C24.5189 -0.166253 22.8708 0.106719 21.4361 0.874824C20.0015 1.64293 18.8606 2.86319 18.1905 4.34616C17.5204 5.82913 17.3587 7.49184 17.7305 9.07613C14.7937 8.92867 11.9207 8.16535 9.29795 6.83569C6.67524 5.50603 4.36141 3.63976 2.50665 1.358C1.87246 2.45198 1.5078 3.72037 1.5078 5.0712C1.50709 6.28726 1.80655 7.48469 2.37962 8.55725C2.95269 9.62982 3.78164 10.5443 4.79292 11.2197C3.6201 11.1824 2.47315 10.8655 1.44755 10.2954V10.3905C1.44743 12.0961 2.0374 13.7492 3.11735 15.0693C4.1973 16.3894 5.70072 17.2952 7.3725 17.633C6.28452 17.9274 5.14385 17.9708 4.03664 17.7598C4.50832 19.2274 5.4271 20.5107 6.66437 21.4301C7.90164 22.3495 9.39545 22.859 10.9367 22.8873C8.32036 24.9411 5.08922 26.0552 1.76306 26.0503C1.17386 26.0505 0.585165 26.0161 0 25.9473C3.37625 28.1181 7.30645 29.2702 11.3204 29.2657C24.908 29.2657 32.3359 18.0119 32.3359 8.25168C32.3359 7.93458 32.328 7.61431 32.3138 7.29722C33.7586 6.25234 35.0058 4.95846 35.9968 3.4762L36 3.47145V3.47145Z"
            fill="#808080"
        />
    </svg>
);

export default TwitterIcon;