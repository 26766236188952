import React from 'react';

const TiktokIcon = () => (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#808080" d="M27.5,6.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.5-0.4-1.1-0.8-1.5-1.3c-1.1-1.3-1.5-2.6-1.7-3.5h0
    c-0.1-0.8-0.1-1.2-0.1-1.2h-5.1v19.7c0,0.3,0,0.5,0,0.8c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1.4-0.9,2.7-2.2,3.4
    c-0.6,0.4-1.4,0.6-2.1,0.6c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3c0.4,0,0.9,0.1,1.3,0.2l0-5.2c-2.6-0.3-5.3,0.4-7.3,2.1
    C7.4,14.1,6.7,15,6.1,16c-0.2,0.4-1,1.9-1.1,4.3c-0.1,1.4,0.4,2.8,0.5,3.4v0c0.1,0.3,0.6,1.5,1.4,2.5c0.6,0.8,1.4,1.5,2.2,2.1v0l0,0
    c2.5,1.7,5.2,1.6,5.2,1.6c0.5,0,2.1,0,3.9-0.9c2-0.9,3.1-2.4,3.1-2.4c0.7-0.8,1.3-1.8,1.7-2.8c0.5-1.2,0.6-2.7,0.6-3.2V10.1
    c0.1,0,0.9,0.6,0.9,0.6s1.2,0.8,3,1.3c1.3,0.4,3.1,0.4,3.1,0.4V7.3C30.1,7.4,28.8,7.2,27.5,6.5z" fill="#808080"/>
    </svg>
);

export default TiktokIcon;