import React from 'react';

import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import TwitterIcon from './TwitterIcon';
import TiktokIcon from './TiktokIcon';
/* import YoutubeIcon from './YoutubeIcon'; */

import './SocialLinks.css';

const SocialLinks = () => {
    return (
        <div className="box">
            <a href="https://www.facebook.com/teleboing" target="_blank" rel="noreferrer" className="iconLink">
                <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/teleboing" target="_blank" rel="noreferrer" className="iconLink">
                <InstagramIcon />
            </a>
            <a href="https://twitter.com/teleboing" target="_blank" rel="noreferrer" className="iconLink">
                <TwitterIcon />
            </a>
            <a href="https://www.tiktok.com/@teleboing" target="_blank" rel="noreferrer" className="iconLink">
                <TiktokIcon />
            </a>
            
            {/* <a href="https://www.youtube.com/channel/" target="_blank" rel="noreferrer" className="iconLink">
                <YoutubeIcon />
            </a> */}
        </div>
    );
};

export default SocialLinks;