import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA5b5OQn30RV4jJSlOo_4N0XLw-t5liaHA",
  authDomain: "help-teleboing.firebaseapp.com",
  projectId: "help-teleboing",
  storageBucket: "help-teleboing.appspot.com",
  messagingSenderId: "19741894157",
  appId: "1:19741894157:web:d1051b586bb22095839744"
};

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const auth = firebase.auth();
  const storage = firebase.storage();
  
  export { auth, storage };
  export default firebaseApp;