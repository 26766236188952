import React from 'react';
import "./MediaCenter.css";
import Nav from '../components/Nav';
import Social from "../components/Footer/SocialLinks";
import Copyright from "../components/Footer/Copyright";
import arrowleft from "../assets/icons/arrow-left.svg";

import { Link } from "react-router-dom";

function MediaCenter() {

    const url1 = "https://firebasestorage.googleapis.com/v0/b/help-TELEBOING.appspot.com/o/resources%2Fmedia%2Fprint-TELEBOING-media01.jpg?alt=media&token=b08442a0-9a50-44d2-9c45-6ed34753ab30";

    const url2 = "https://firebasestorage.googleapis.com/v0/b/help-TELEBOING.appspot.com/o/resources%2Fmedia%2Fprint-TELEBOING-media02.jpg?alt=media&token=954cbad0-a48c-4129-9156-8e3d2124c90d";

    const url3 = "https://firebasestorage.googleapis.com/v0/b/help-TELEBOING.appspot.com/o/resources%2Fmedia%2Fprint-TELEBOING-media03.jpg?alt=media&token=8be626a3-761a-4506-a8a1-3870e0afdd8f";

    return (
        <div>
            <Nav />
            <div className="mediacenter">

                <Link to="/" className="mediacenter__back" >
                <img 
                src={arrowleft}
                alt="to TELEBOING helpdesk"
                />
                <p className='mediacenter__back__msg' href="/" >Regresar a la página de inicio de Ayuda</p>
                </Link>

                <div className='mediacenter__msg'>
                    <h1>Media Center</h1>
                    <p>Elige el soporte gráfico que prefieras compartir con tus seguidores y audiencia. Cada pieza audiovisual tiene un propósito de comunicación.</p>
                </div>

                <div className="mediacenter__images">
                    <div className="mediacenter__images__item">
                        <img src={url1} alt='' />
                        <p>Banner #1: Tus periodistas favoritos.</p>
                        <a href={url1} target="_blank" rel="noreferrer">descargar el banner</a>
                    </div>
                    <div className="mediacenter__images__item">
                        <img src={url2} alt='' />
                        <p>Banner #2: Varias voces es una verdad.</p>
                        <a href={url2} target="_blank" rel="noreferrer">descargar el banner</a>
                    </div>
                    <div className="mediacenter__images__item">
                        <img src={url3} alt='' />
                        <p>Banner #3: 24/7 y sin publicidad.</p>
                        <a href={url3} target="_blank" rel="noreferrer">descargar el banner</a>
                    </div>
                </div>

            </div>
            <Social />
            <Copyright />
        </div>
    )
}

export default MediaCenter;
